import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { SectionWrapper, GridItem } from './dashboard-charts.styled';
import { CountryChart } from '../../../../components/app/chart/country-chart';
import { SectorChart } from '../../../../components/app/chart/sector-chart';
import { Slideshow } from '../../../../components/app/swiper/swiper';
import { SkeletonDashboardCharts } from '../../../../components/app/skeleton/dashboard/charts/skeleton-dashboard-charts';
import { useRootStore } from '../../../../providers/root-store-provider';
import { Section } from '../../../../components/app/section/section';
import { Responsive } from '../../../../components/app/responsive/responsive';
import { AssetTypeChart } from '../../../../components/app/chart/asset-type-chart';
import { AssetDistributionChart } from '../../../../components/app/chart/asset-distribution-chart';
import { CurrencyChart } from '../../../../components/app/chart/currency-chart';
import { Grid } from '../../../../components/app/grid/grid';
import { Badge } from '../../../../components/app/badge/badge';
import { NoData } from '../../../../components/app/no-data/no-data';
import { DashboardChartsItem } from './item/dashboard-charts-item';

export const DashboardCharts: React.FC = observer(() => {
    const { t } = useTranslation();
    const { statisticStore, authStore, portfolioStore } = useRootStore();
    const { ratiosStore } = statisticStore;

    if ((ratiosStore.loading && !ratiosStore.hasRatios) || portfolioStore.selectedPortfolio.isRecalculating) {
        return <SkeletonDashboardCharts />;
    }

    if (!ratiosStore.hasRatios) {
        return (
            <SectionWrapper>
                <NoData>{t('layout.tables.noDataForRatios')}</NoData>
            </SectionWrapper>
        );
    }

    return (
        <SectionWrapper>
            <Section>
                <Responsive breakpoint={'vl'} type={'max'}>
                    <Slideshow>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.assetType')}
                                chart={<AssetTypeChart />}
                            />
                        </GridItem>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.portfolio')}
                                chart={<AssetDistributionChart />}
                            />
                        </GridItem>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.currency')}
                                chart={<CurrencyChart orientation={'horizontal'} />}
                            />
                        </GridItem>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.country')}
                                chart={<CountryChart isFree={authStore.userHasFreeTariff} />}
                                badge={
                                    authStore.userHasFreeTariff && (
                                        <Badge variant="purple" size="small">
                                            Premium
                                        </Badge>
                                    )
                                }
                            />
                        </GridItem>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.sector')}
                                chart={<SectorChart isFree={authStore.userHasFreeTariff} />}
                                badge={
                                    authStore.userHasFreeTariff && (
                                        <Badge variant="purple" size="small">
                                            Premium
                                        </Badge>
                                    )
                                }
                            />
                        </GridItem>
                    </Slideshow>
                </Responsive>
                <Responsive breakpoint={'vl'} type={'min'}>
                    <Grid cols={{ xs: 1, md: 2, vl: 3 }}>
                        <Grid cols={{ xs: 1 }}>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.assetType')}
                                    chart={<AssetTypeChart />}
                                />
                            </GridItem>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.portfolio')}
                                    chart={<AssetDistributionChart />}
                                />
                            </GridItem>
                        </Grid>
                        <GridItem>
                            <DashboardChartsItem
                                title={t('pages.index.graphTitle.currency')}
                                chart={<CurrencyChart orientation={'vertical'} />}
                            />
                        </GridItem>
                        <Grid cols={{ xs: 1 }}>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.country')}
                                    chart={<CountryChart isFree={authStore.userHasFreeTariff} />}
                                    badge={
                                        authStore.userHasFreeTariff && (
                                            <Badge variant="purple" size="small">
                                                Premium
                                            </Badge>
                                        )
                                    }
                                />
                            </GridItem>
                            <GridItem>
                                <DashboardChartsItem
                                    title={t('pages.index.graphTitle.sector')}
                                    chart={<SectorChart isFree={authStore.userHasFreeTariff} />}
                                    badge={
                                        authStore.userHasFreeTariff && (
                                            <Badge variant="purple" size="small">
                                                Premium
                                            </Badge>
                                        )
                                    }
                                />
                            </GridItem>
                        </Grid>
                    </Grid>
                </Responsive>
            </Section>
        </SectionWrapper>
    );
});
